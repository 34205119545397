import tw, { css } from 'twin.macro'
import Image from 'next/image'

import { Heading as HeadingType } from '@/types/heading'

import useBookNow from '@/hooks/use-book-now'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'
import { NextLink } from '@/atoms/next-link'

import { RenderMarkdown } from '@/lib/render-markdown'

type Props = {
  data: HeadingType
  className?: string
  slug?: string
}

export function HeaderContent({ data, className }: Props) {
  const { image, strapline, heading, image_link } = data
  const { event, pathPrefix, isWhatsOn, slug } = useBookNow()
  return (
    <Container
      className={`mt-14 mb-6 text-theme-inverse flex items-center justify-between flex-wrap ${className}`}>
      <div>
        <p tw="leading-6 tracking-wide">{strapline}</p>
        <Heading
          as="h1"
          variant="h2"
          tw="mt-2 text-theme-accent leading-none"
          css={[
            css`
              font-size: clamp(1.2rem, 8vw, 2.5rem);
              line-height: 1.3em;
            `,
          ]}>
          <RenderMarkdown>{heading}</RenderMarkdown>
        </Heading>
      </div>
      {isWhatsOn &&
      event?.attributes?.live_res_occasion_id?.replace(/\s/g, '').length ? (
        <NextLink
          to={`${pathPrefix}/casinos/bookings/${slug}`}
          tw="bg-theme-accent py-2 px-4 min-w-[200px] text-white text-center uppercase">
          Book Now
        </NextLink>
      ) : null}
      {!isWhatsOn && image?.data && (
        <>
          {image_link ? (
            <NextLink to={image_link}>
              <Image
                src={image.data.attributes.url}
                width={image.data.attributes.width}
                height={image.data.attributes.height}
              />
            </NextLink>
          ) : (
            <Image
              src={image.data.attributes.url}
              width={image.data.attributes.width}
              height={image.data.attributes.height}
            />
          )}
        </>
      )}
    </Container>
  )
}
