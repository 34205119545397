import tw from 'twin.macro'

function FieldError({ name, message, className, ...rest } : { name: string, className?: string, message: string | undefined }) {
  return (
    <p className={`mt-2 text-sm text-theme-accent ${className}`} id={`${name}-error`} role="alert" {...rest}>
    {message}
    </p>  
  )
}

export { FieldError }