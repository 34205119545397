import { ReactNode } from 'react'
import tw from 'twin.macro'

type Props = {
  htmlFor: string,
  required?: boolean,
  children: ReactNode,
  onChange?: () => void
}

function Label({ htmlFor, required, children, ...rest }: Props) {
  return (
    <label htmlFor={htmlFor} tw="select-none" {...rest}>
      {children}
      {required && <>*</>}
    </label>
  )
}

export { Label }