import { useRef, ChangeEvent } from 'react'

function useScrollIntoView(
	options: {
		behavior?: 'auto' | 'smooth'
		block?: 'start' | 'center' | 'end' | 'nearest'
		inline?: 'start' | 'center' | 'end' | 'nearest'
	} = {
		behavior: 'smooth',
		block: 'nearest',
	}
) {
	const scrollRef = useRef<HTMLDivElement>(null)

	function handleScroll(event: ChangeEvent<Element>) {
		if (scrollRef.current) return scrollRef.current.scrollIntoView(options)
		event.target.scrollIntoView(options)
	}

	return { scrollRef, handleScroll }
}

export { useScrollIntoView }
