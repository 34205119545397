import { forwardRef } from 'react'
import tw from 'twin.macro'
import { UseFormRegister } from 'react-hook-form'

import { SelectField as SelectFieldTypes } from '@/types/forms'

const SelectField = forwardRef<
	HTMLSelectElement,
	// @ts-ignore
	SelectFieldTypes & ReturnType<UseFormRegister<SelectFieldTypes>>	
>( ({ name, autoComplete, placeholder, children, defaultValue ='', ...rest }, ref) => {
	return (
		<select
			name={name}
			id={name}
			autoComplete={autoComplete}
			tw='block w-full focus:(outline-none ring-2 ring-red-500 border-red-500) border-transparent bg-[#F0F0F0] placeholder-gray-300 disabled:cursor-not-allowed'
			placeholder={placeholder}
			defaultValue={defaultValue}
			{...rest}
			ref={ref}
		>
			<option value="">{placeholder}</option>
			{children}
		</select>
	)
})

export { SelectField }
