import { forwardRef } from 'react'
import tw from 'twin.macro'
import { UseFormRegister } from 'react-hook-form'

import { TextField as TextFieldTypes } from '@/types/forms'

const  TextField = forwardRef<
  HTMLInputElement,
  TextFieldTypes & ReturnType<UseFormRegister<TextFieldTypes>>
>(({ type = 'text', name, autoComplete, placeholder, onKeyDown, ...rest}, ref) => {

  return (
    <input
      type={type}
      name={name}
      id={name}
      autoComplete={autoComplete}
      tw="block w-full focus:(outline-none ring-2 ring-red-500 border-red-500) border-transparent bg-[#F0F0F0] placeholder-gray-300 disabled:cursor-not-allowed"
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      {...rest}
      ref={ref}
    />
  )
})

export { TextField }