// @ts-nocheck
import { Children, cloneElement, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { Button } from '@/atoms/button'

const themes = {
  dark: {
    background: 'bg-theme-secondary opacity-70 px-4 pt-5 pb-4 py sm:p-6',
    text: 'text-white'
  },
  light: {
    background: 'bg-white',
    text: 'text-black'
  }
}

function Icon({ children }) {
  return (
    <div className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-white">
      {children}
    </div>                  
  )
}

function Title({ children }) {
  return (
    <Dialog.Title as="h3" className="mt-8 text-4xl leading-6 font-light uppercase text-center">
      {children}
    </Dialog.Title>     
  )
}

function Description({ children }) {
  return (
    <Dialog.Description className="mt-10 font-light text-center space-y-5">
      {children}
    </Dialog.Description>     
  )
}

function Modal({ open, setOpen, variant = 'dark', close = true, children, ...rest }: {
  open: boolean,
  setOpen: (boolean: boolean) => void,
  variant?: 'light' | 'dark',
  close?: boolean,
  children: ReactElement
}) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-40 inset-0 overflow-y-auto" open={open} onClose={() => setOpen(false)} { ...rest}>
        {/* Use the overlay to style a dim backdrop for your dialog */}
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />        
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`inline-block align-bottom text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-2xl sm:w-full ${themes[variant].background} ${themes[variant].text}`}>
              {Children.map(children, child =>
                cloneElement(child),
              )}

              {close && 
                <div className="mt-5 sm:mt-6">
                  <Button
                    theme="default"
                    variant="inverse"
                    fullWidth={true}
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </Button>
                </div>              
              }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Modal.Icon = Icon
Modal.Title = Title
Modal.Description = Description

export { Modal }
